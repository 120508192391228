/* Header container */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  position: fixed;
  background-color: var(--background-color);
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.header:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}

.gradient {
  height: 6px;
  background: linear-gradient(to right,
  #920002 0%,     /* bosch-red-30 */
  #920002 7.14%,
  #be0004 7.14%,  /* bosch-red-40 */
  #be0004 14.28%,
  #ed0007 14.28%, /* bosch-red-50 */
  #ed0007 21.42%,
  #9e2896 28.56%, /* bosch-purple-40 */
  #9e2896 35.7%,
  #791d73 35.7%,  /* bosch-purple-30 */
  #791d73 42.84%,
  #004975 42.84%, /* bosch-blue-30 */
  #004975 49.98%,
  #00629a 49.98%, /* bosch-blue-40 */
  #00629a 57.12%,
  #007bc0 57.12%, /* bosch-blue-50 */
  #007bc0 64.26%,
  #56b0ff 64.26%, /* bosch-blue-70 */
  #56b0ff 71.4%,
  #9dc9ff 71.4%,  /* bosch-blue-80 */
  #9dc9ff 78.54%,
  #00512a 78.54%, /* bosch-green-30 */
  #00512a 85.68%,
  #006c3a 85.68%, /* bosch-green-40 */
  #006c3a 92.82%,
  #00884a 92.82%, /* bosch-green-50 */
  #00884a 100%
);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}

/* Centered content container */
.header-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  position: relative;
}

/* Logo container */
.header-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}

.header-logo-container::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 2px;
  background: linear-gradient(to right, var(--red), var(--burgundy-red));
  border-radius: 2px;
}

/* Logo styling */
.logo {
  height: 70px;
  width: auto;
  cursor: pointer;
  margin-bottom: 8px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  transition: transform 0.3s ease, filter 0.3s ease;
}

.logo:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.15));
}

.company-name {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  background: linear-gradient(to right, var(--red), var(--burgundy-red));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  text-align: center;
  letter-spacing: 0.5px;
  transition: transform 0.3s ease;
}

.company-name:hover {
  transform: scale(1.03);
}

.company-name p {
  margin: 0;
}

/* Hamburger icon styling */
.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--burgundy-red);
  z-index: 1000;
  position: absolute;
  right: 20px;
  top: 20px;
  transition: transform 0.3s ease;
}

.hamburger:hover {
  transform: scale(1.1);
}

/* Navigation links */
.header-nav-links {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  padding: 5px 15px;
  border-radius: 30px;
}
 
.header-dropdown {
  position: relative;
  display: inline-block;
}
 
.header-dropdown > a {
  padding: 10px 15px;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease;
  letter-spacing: 0.3px;
}
 
/* Underline animation for main links */
.header-dropdown > a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  bottom: -2px;
  left: 50%;
  background: linear-gradient(to right, var(--red), var(--burgundy-red));
  transition: all 0.3s ease;
  transform: translateX(-50%);
  border-radius: 3px;
}
 
.header-dropdown > a:hover::after {
  width: 90%;
}
 
.header-dropdown > a:hover {
  color: var(--burgundy-red);
  transform: translateY(-2px);
}
 
.header-dropdown-content {
  display: none;
  position: absolute;
  min-width: 220px;
  background-color: var(--background-color);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px 0;
  z-index: 1000;
  margin-top: 10px;
  border: 1px solid var(--subtle-background-color);
  backdrop-filter: blur(10px);
  transform-origin: top center;
}
 
.header-dropdown:hover .header-dropdown-content,
.header-dropdown-content.show {
  display: block;
  animation: dropdownFadeIn 0.3s ease;
}
 
.header-dropdown-content a {
  color: var(--text-color);
  padding: 12px 20px;
  text-decoration: none;
  display: block;
  font-size: 14px;
  font-weight: 500;
  border-left: 3px solid transparent;
  transition: all 0.3s ease;
  margin: 2px 0;
}
 
.header-dropdown-content a:hover {
  background-color: var(--subtle-background-color);
  color: var(--burgundy-red);
  border-left: 3px solid var(--burgundy-red);
  padding-left: 25px;
  transform: translateX(4px);
}
 
@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px) scaleY(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scaleY(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Styles for smaller screens */
@media (max-width: 768px) {
  .header {
    padding: 15px;
    height: auto;
  }
  
  .header-logo-container {
    margin-bottom: 5px;
  }
  
  .logo {
    height: 50px;
  }
  
  .company-name {
    font-size: 26px;
  }
  
  .hamburger {
    display: block;
  }

  .header-nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--background-color);
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    flex-direction: column;
    gap: 10px;
    border-radius: 0;
    margin-top: 0;
  }
 
  .header-nav-links.open {
    display: flex;
    animation: mobileMenuFadeIn 0.3s ease;
  }
 
  .header-dropdown {
    width: 100%;
  }
 
  .header-dropdown-content {
    position: static;
    width: 100%;
    margin-left: 1rem;
    box-shadow: none;
    border-left: 2px solid var(--burgundy-red);
    animation: none;
    border-radius: 0;
  }
 
  .header-dropdown-content::before {
    display: none;
  }

  .header-dropdown-content a {
    padding: 0.75rem 1rem;
  }
  
  @keyframes mobileMenuFadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}