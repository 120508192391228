.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  padding-top: 0; /* No extra padding since carousel has its own margin */
  width: 100%;
  overflow-x: hidden;
}

.home-container {
  max-width: 1800px;
  padding: 20px;
  color: var(--text-color);
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

/* Specific styles for the products section to maintain spacing */
/* .home-container:nth-child(3) {
  padding-top: 20px;
}

.home-container:nth-child(3) h1 {
  margin-bottom: 40px;
} */

.company-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px
}

.home-container h1 {
  font-size: 64px;
  font-weight: 1000;
  color: var(--primary-color);
}

.company-description {
  max-width: 700px;
  line-height: 30px;
  font-size: 20.8px;
  text-align: left;
}

.home-card-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  background-color: var(--subtle-background-color);
  border-radius: 8px;
  cursor: pointer;
  /* padding: 20px 100px; */
}

.home-card {
  border-radius: 8px;
  padding: 20px 20px 0px 20px;
  position: relative;
  width: 200px;
  overflow: hidden;
  transition: width 0.3s ease, opacity 0.5s ease;
}

.home-card-image {
  border-radius: 8px;
  width: 200px;
  height: 280px;
  object-fit: contain;
  /* Keep the main image size constant */
}

.home-card-container:hover .home-card {
  /* padding: 0px; */
  opacity: 0;
  /* Fade out all cards when container is hovered */
}

.home-card:hover {
  width: 800%;
}

.home-card-container:hover .home-card:hover {
  padding: 20px 20px 0px 20px;
  opacity: 1;
  z-index: 1;
}

.additional-images {
  display: none;
  position: absolute;
  top: 0;
  left: 220px;
  margin: 10px;
  width: calc(100% - 200px);
}

.additional-images .image-wrapper {
  display: inline-block;
  position: relative;
  text-align: center;
  margin: 10px;
}

.additional-images img {
  border-radius: 8px;
  margin: 0;
  margin-top: 30px;
  width: 160px;
  height: 160px;
  /* height: auto; */
  object-fit: contain;
}

.additional-images .image-label {
  display: block;
  margin-top: 5px;
  color: var(--text-color);
  font-size: 14px;
}

.home-card:hover .additional-images {
  display: block;
}

/* -- Diesel Center */

.services-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 0 2rem;
  max-width: 1400px;
  margin: 0 auto;
  cursor: pointer;
}

.service-card {
  position: relative;
  flex: 1;
  max-width: 600px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  background: var(--background-color);
}

.service-card:hover {
  transform: translateY(-5px);
}

.main-image-container {
  position: relative;
  width: 300px;
  height: 400px;
}

.main-image {
  /* width: 100%; */
  width: 300px;
  height: 400px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.service-card:hover .main-image {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(51, 51, 51, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.service-card:hover .overlay {
  background: rgba(51, 51, 51, 0.7);
}

.content {
  text-align: center;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.service-card:hover .content {
  transform: translateY(-30px);
}

.overlay h2 {
  color: var(--background-color);
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* .overlay p {
  color: #f0f0f0;
  font-size: 1.1rem;
  max-width: 80%;
  margin: 0 auto;
} */

.hover-images {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 0 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.service-card:hover .hover-images {
  opacity: 1;
  transform: translateY(0);
}

.hover-image-container {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid white;
  transition: transform 0.3s ease;
}

.hover-image-container:hover {
  transform: scale(1.1);
}

.hover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* -- Diesel Center end */

.choose-us-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap; /* Prevent wrapping to ensure single row */
  gap: 30px;
  margin: 40px 0 20px;
  width: 100%;
  max-width: 1200px;
}

.choose-us-card {
  background-color: white;
  border-radius: 12px;
  padding: 30px 25px;
  text-align: center;
  flex: 1; /* Equal width for all three cards */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  max-width: calc(33.33% - 20px); /* Prevent cards from growing too wide */
}

.choose-us-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.choose-us-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
  background-color: rgba(237, 28, 36, 0.1);
  border-radius: 50%;
  transition: transform 0.3s;
}

.choose-us-card:hover .choose-us-icon-container {
  transform: scale(1.1);
}

.choose-us-icon {
  width: 60px;
  height: auto;
}

.choose-us-card h3 {
  color: var(--red);
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 600;
  transition: color 0.3s;
}

.choose-us-card p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 25px;
  flex-grow: 1;
}

.choose-us-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 15px;
  border-top: 1px solid #eee;
  color: var(--red);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
}

.choose-us-card-footer:hover {
  color: #c41818;
}

.choose-us-card-footer i {
  transition: transform 0.3s;
}

.choose-us-card-footer:hover i {
  transform: translateX(5px);
}

/* Carousel styles */
.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 0;
  max-height: 500px;
  margin-top: 170px; /* Increased to account for taller styled header */
  z-index: 1;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.carousel-slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.carousel-slide {
  min-width: 100%;
  height: 500px;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-indicators {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.carousel-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-indicator.active {
  background-color: white;
}

/* Why Choose Us section with background */
.why-choose-us {
  background-color: var(--red);
  width: 100%;
  padding: 60px 0;
  margin: 60px 0;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.why-choose-us:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: url('/images/pattern.png'); */
  opacity: 0.1;
  z-index: 0;
}

.why-choose-us .company-info {
  max-width: 1200px;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.why-choose-us h1 {
  color: var(--subtle-background-color);
  margin-bottom: 15px;
}

.section-description {
  color: var(--subtle-background-color);
  text-align: center;
  font-size: 20px;
  max-width: 800px;
  margin: 0 auto 40px;
}

/* Partners section */
.partners-section {
  width: 100%;
  padding: 40px 0;
  margin-bottom: 60px;
  box-sizing: border-box;
}

.partners-section .company-info {
  max-width: 1200px;
  padding: 0 20px;
  box-sizing: border-box;
}

.partners-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 30px;
}

.partner-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  transition: transform 0.3s ease;
}

.partner-logo:hover {
  transform: scale(1.05);
}

.partner-logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 80px;
  /* filter: grayscale(70%); */
  transition: filter 0.3s ease;
}

/* .partner-logo:hover img {
  filter: grayscale(0%);
} */

.partner-logo p {
  margin-top: 10px;
  font-weight: 500;
  color: var(--text-color);
}

/* Welcome section styling */
.welcome-section {
  padding: 60px 20px 40px;
  background-color: #ffffff; /* Solid white background to match rest of page */
  border-bottom: none; /* Remove the bottom border */
  margin-top: 0; /* Remove top margin to eliminate gap */
}

.welcome-section h1 {
  margin-bottom: 40px;
  text-align: center;
  position: relative;
}

.welcome-section h1::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background-color: var(--red);
}

.welcome-section .highlight {
  color: var(--red);
}

.welcome-content {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.welcome-image {
  flex: 1;
  max-width: 500px;
}

.welcome-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.welcome-image img:hover {
  transform: scale(1.02);
}

.welcome-text {
  flex: 1;
  padding: 0 20px;
}

.company-description {
  max-width: 100%;
  line-height: 1.8;
  font-size: 18px;
  margin-bottom: 30px;
  color: #444;
  text-align: left;
}

.welcome-features {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 25px;
}

.welcome-feature {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: calc(50% - 10px);
}

.welcome-feature:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.welcome-feature i {
  color: var(--red);
  font-size: 18px;
  margin-right: 10px;
}

.welcome-feature span {
  font-weight: 500;
  color: #333;
}

/* Media queries for welcome section */
@media (max-width: 992px) {
  .welcome-content {
    flex-direction: column;
  }
  
  .welcome-image, .welcome-text {
    max-width: 100%;
  }
  
  .welcome-section h1 {
    font-size: 48px;
  }
}

/* Media queries for responsive design */
@media (max-width: 992px) {
  .choose-us-box {
    gap: 20px;
  }
  
  .choose-us-card {
    padding: 25px 20px;
  }
}

/* Main mobile styles - consolidating all styles here */
@media (max-width: 768px) {
  .carousel-slide {
    height: 300px;
  }

  .carousel-container {
    margin-top: 110px;
    max-height: 300px;
    margin-bottom: 20px;
  }

  .why-choose-us {
    padding: 40px 0;
    margin: 40px 0;
  }
  
  .section-description {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .choose-us-box {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .choose-us-card {
    width: 100%;
    max-width: 250px;
    margin: 10px 0;
  }
  
  .choose-us-icon-container {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }
  
  .choose-us-icon {
    width: 45px;
  }
  
  .choose-us-card h3 {
    font-size: 20px;
  }

  .partners-logo-container {
    gap: 20px;
  }

  .partner-logo {
    width: 100px;
  }

  .welcome-section {
    padding: 40px 20px;
  }

  .services-container {
    flex-direction: column;
    gap: 2rem;
  }
  
  .company-description {
    font-size: 16px;
  }
  
  .welcome-feature {
    width: 100%;
  }
  
  .welcome-section h1 {
    font-size: 36px;
  }

  /* Center align all section headings in mobile view */
  .home-container h1 {
    text-align: center;
    width: 100%;
  }
}