.power-tools-accessories-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 200px;
}

.power-tools-accessories-container {
  width: 100%;
  color: var(--text-color);
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.power-tools-accessories-container h1 {
  font-size: 64px;
  color: var(--primary-color);
}

.p-and-m-header {
  margin-bottom: 32px;
}

.p-and-m-header h1 {
  font-size: 28px;
  color: #333;
  margin-bottom: 24px;
  font-weight: 600;
}

.p-and-m-search {
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;
  position: relative;
}

.p-and-m-search input {
  width: 100%;
  padding: 12px 40px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.2s;
}

.p-and-m-search input:focus {
  border-color: #666;
}

.p-and-m-search-icon {
  position: absolute;
  left: 12px;
  top: 55%;
  transform: translateY(-50%);
  color: #666;
  pointer-events: none;
}

.p-and-m-categories {
  margin: 10px;
  margin-bottom: 0px;
}

.p-and-m-category-buttons {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.p-and-m-category-buttons button {
  padding: 8px 16px;
  border: 1px solid var(--metallic-silver);
  border-radius: 8px;
  background: var(--background-color);
  cursor: pointer;
  transition: all 0.2s;
  font-size: 15px;
  color: var(--text-color);
}

.p-and-m-category-buttons button:hover {
  background: var(--subtle-background-color);
}

.p-and-m-category-buttons button.active {
  background: var(--burgundy-red);
  color: var(--background-color);
  border: 1px solid var(--burgundy-red);
}

.p-and-m-items {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 20px;
  margin: 10px 5px;
}

.p-and-m-items-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin: -10px;
  /* Compensate for card padding */
}

.p-and-m-card {
  margin: 10px;
  background: var(--subtle-background-color-color);
  border-radius: 8px;
  padding: 16px;
  transition: all 0.2s;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  width: 210px;
  /* Minimum width for cards */
}

.p-and-m-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.p-and-m-card-header {
  margin-bottom: 12px;
}

.p-and-m-card-header h3 {
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-and-m-rating {
  display: flex;
  align-items: center;
  gap: 6px;
}

.p-and-m-stars {
  color: #ffd700;
  letter-spacing: 1px;
  font-size: 14px;
}

.p-and-m-rating-number {
  color: #666;
  font-size: 13px;
}

.p-and-m-specs {
  color: #666;
  font-size: 13px;
  margin-bottom: 16px;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-and-m-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-and-m-price {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.p-and-m-arrow {
  color: #666;
  font-size: 20px;
  text-decoration: none;
  color: inherit;
}

.pagination {
  display: flex;
  justify-content: center;
  /* Center the pagination controls */
  margin-top: 20px;
  /* Space above the pagination */
  margin-bottom: 20px;
}

.pagination button {
  background-color: var(--subtle-background-color);
  /* Bootstrap primary color */
  color: var(--burgundy-red);
  /* Text color */
  border: none;
  /* Remove default border */
  padding: 10px 15px;
  /* Padding for buttons */
  margin: 0 5px;
  /* Space between buttons */
  cursor: pointer;
  /* Pointer cursor on hover */
  border-radius: 5px;
  /* Rounded corners */
  transition: background-color 0.3s;
  /* Smooth background color transition */
  font-size: 16px;
}

.pagination button:hover {
  background-color: var(--metallic-silver);
  /* Darker shade on hover */
}

.pagination button:disabled {
  background-color: #c0c0c0;
  /* Gray color for disabled buttons */
  cursor: not-allowed;
  /* Not-allowed cursor for disabled buttons */
}

.pagination button.active {
  background-color: var(--metallic-silver);
  /* Same as hover color for consistency */
  /* font-weight: bold; Make the active page bold */
}

@media (max-width: 768px) {
  .power-tools-accessories-wrapper {
    margin-top: 130px;
  }

  .power-tools-accessories-container {
    width: 80%;
  }

  .power-tools-accessories-container {
    flex-direction: column;
    margin: 5px;
  }

  .power-tools-accessories-container h1 {
    font-size: 36px;
  }

  .p-and-m-header h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .p-and-m-category-buttons {
    gap: 8px;
  }

  .p-and-m-category-buttons button {
    padding: 6px 12px;
    font-size: 14px;
  }

  .p-and-m-items {
    width: 100%;
    justify-content: center;
  }

  .p-and-m-card {
    width: 160px;
    margin: 8px;
    padding: 12px;
  }

  .pagination button {
    padding: 8px 12px;
    /* Smaller padding on mobile */
    margin: 0 2px;
    /* Reduced margin between buttons */
    font-size: 14px;
    /* Slightly smaller font size */
  }

  .pagination {
    flex-wrap: wrap;
    /* Allow buttons to wrap on smaller screens */
    gap: 8px;
    /* Add gap between wrapped rows */
    padding: 0 10px;
    /* Add some horizontal padding */
  }

  .p-and-m-card-header h3 {
    font-size: 14px;
  }

  .p-and-m-specs {
    font-size: 12px;
    margin-bottom: 12px;
  }

  .p-and-m-price {
    font-size: 16px;
  }
}