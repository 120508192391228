:root {
  --primary-color: #333333;
  --text-color: #333333;
  --subtle-background-color: #F5F5F5;
  --background-color: #FFFFFF;
  --deep-red: #990000;
  --burgundy-red: #752424;
  --metallic-silver: #C0C0C0;
  --dark-metallic-silver: #909090;
  --red: #b5474c;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public//fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Montserrat', Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.AppContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensures the footer stays at the bottom */
}

.App {
  flex: 1;
  /* Allows the main content to grow and push the footer down */
}

.logo img {
  height: 50px;
  width: auto;
}

/* .nav-links a {
  color: var(--primary-color);
  margin: 0 15px;
  text-decoration: none;
  font-weight: bold;
} */

/* .nav-links a:hover {
  color: var(--secondary-color);
} */