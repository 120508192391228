.product-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding: 2rem;
  margin-top: 200px;
}

.product-not-found-content {
  text-align: center;
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-not-found-content h2 {
  color: #dc3545;
  margin-bottom: 1rem;
}

.product-not-found-content p {
  color: #6c757d;
  margin-bottom: 0.5rem;
}