.sp-card {
  width: 280px;
  height: 220px;
  background: #ffffff;
  border: 1px solid var(--burgundy-red);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.sp-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.sp-card-content {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.sp-card-header {
  text-align: center;
  padding-bottom: 14px;
  border-bottom: 2px solid var(--subtle-background-color);
  position: relative;
}

.sp-card-header::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 2px;
  background-color: var(--burgundy-red);
  transition: width 0.3s ease;
}

.sp-card:hover .sp-card-header::after {
  width: 80px;
}

.sp-part-number {
  margin: 0;
  color: #1B263B;
  font-size: 1.3rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.5px;
}

.sp-bosch-number {
  color: var(--text-color);
  margin: 6px 0 0;
  font-size: 0.95rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
}

.sp-card-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sp-detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.95rem;
  padding: 2px 0;
}

.sp-label {
  color: #555;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.2px;
}

.sp-value {
  color: var(--text-color);
  text-align: right;
  max-width: 60%;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sp-vehicle-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.sp-read-more {
  display: block;
  text-align: center;
  padding: 10px;
  background-color: #f5f5f5;
  color: var(--burgundy-red);
  text-decoration: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  margin-top: 10px;
  border: 1px solid transparent;
}

.sp-read-more:hover {
  background-color: var(--burgundy-red);
  color: var(--background-color);
  /* border-color: #415A77; */
}

/* Add smooth scrollbar for overflow content */
.sp-card * {
  scrollbar-width: thin;
  scrollbar-color: #415A77 #f0f0f0;
}

/* Custom scrollbar styling for Webkit browsers */
.sp-card *::-webkit-scrollbar {
  width: 6px;
}

.sp-card *::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 3px;
}

.sp-card *::-webkit-scrollbar-thumb {
  background: #415A77;
  border-radius: 3px;
}