/* src/components/Footer.css */
footer {
  background-color: var(--red);
  color: var(--subtle-background-color);
  padding: 40px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 20px;
  box-sizing: border-box;
}

.footer-column {
  flex: 1;
  min-width: 250px;
  margin-bottom: 30px;
  padding: 0 15px;
}

.footer-column h3 {
  font-size: 22px;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
  color: #ffffff;
}

.footer-column h3:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 2px;
  background-color: #ffffff;
}

.footer-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin: 0;
}

.footer-details p {
  margin: 5px 0;
  line-height: 1.6;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: var(--subtle-background-color);
  text-decoration: none;
  transition: color 0.3s;
  position: relative;
}

.footer-links a:hover {
  color: #ffffff;
}

.footer-links a:before {
  content: "›";
  margin-right: 8px;
}

.footer-social {
  text-align: center;
  margin: 30px 0;
  padding: 20px 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

.footer-social h3 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #ffffff;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--subtle-background-color);
  text-decoration: none;
  transition: all 0.3s;
}

.social-icon:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-3px);
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 20px 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

.rights-reserved {
  margin: 0;
  font-size: 14px;
  color: var(--subtle-background-color);
}

.footer-legal {
  display: flex;
  gap: 15px;
}

.footer-legal a {
  color: var(--subtle-background-color);
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.footer-legal a:hover {
  color: #ffffff;
  text-decoration: underline;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }
  
  .footer-column {
    margin-bottom: 30px;
    padding: 0;
    width: 100%;
    text-align: center;
  }
  
  .footer-column h3:after {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .footer-details {
    align-items: center;
    text-align: center;
  }
  
  .footer-links li {
    text-align: center;
  }
  
  .footer-bottom {
    flex-direction: column;
    text-align: center;
  }
  
  .rights-reserved {
    margin-bottom: 10px;
  }
  
  .footer-legal {
    justify-content: center;
  }
}